@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

$darkblue: #6271e2;
$cyan: #23d7a5;
$lightblue: #D6EFED;
$hover: lighten($darkblue, 10%);

@keyframes fadeInLeft {
    from {
        opacity: 0.2;
        margin-left: -10%;
    }

    to {
        opacity: 1;
        margin-left: inherit;
    }
}

@media only screen and (max-width: 600px) {
    .btnnavbar {
        display: none;
    }
}

.lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 25%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    box-sizing: border-box;
    border: 4px solid $darkblue;
    border-radius: 50%;
    border-top-color: transparent;
    background: none;
    animation: preloader-spin 1s infinite linear;
}

@keyframes preloader-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

::-webkit-scrollbar {
    border-radius: 50%;
}

/* Track */
::-webkit-scrollbar-track {
    background: #E4E4E4;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $darkblue;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: lighten($darkblue, 10%);
}

h1, h2 {
    font-size: max(2vw, 20px);
    line-height: max(6vw, 60px);
    text-align: center;
}

h3 {
    font-size: max(1.4vw, 14px);
    line-height: max(2.8vw, 28px);
    text-align: center;

    span {
        color: #51557E;
    }
}


body {
    font-family: 'Poppins', sans-serif;
    min-height: 100%;
    margin: auto;
}

.home {

    div {
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 0% 3% 0% 3%;
        line-height: 500%;
        margin-top: 10%;
        align-items: center;
        width: 100%;
    }

    .textblock {
        display: block;
        margin-top: 0;
    }

    img {
        width: 40%;
        min-width: 200px;

        &.team {
            width: 30%;
            border-radius: 100px;
            border: 10px solid $darkblue;
        }
    }

    h1 {
        white-space: nowrap;
        font-size: max(2.8vw, 28px);
        text-align: left;
        margin-top: max(6vw, 60px);
        margin-bottom: 0;
    }

    p {
        width: 100%;
        font-weight: lighter;
        overflow-wrap: normal;
        font-size: max(1.4vw, 14px);
        line-height: max(2.8vw, 28px);
    }
}


@media screen and (max-width: 800px) {
    .home {
        div {
            display: block;
            text-align: center;
        }

        img {
            width: 50%;
            margin-top: 5%;
        }

        h1 {
            text-align: center;
        }
    }
}

.dropbtn {
    background-color: #fff;
    padding: 5px 10px;
    border-width: 3px;
    border-style: solid;
    border-radius: 5px;
    cursor: pointer;
    font-size: 30px;
    transition: color 0.3s;
    transition: background 0.3s;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */

    &:hover {
        background-color: darken(#fff, 10%);
    }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;

    &:hover .dropdown-content {
        display: block;
    }
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */

    a {
        font-size: max(1.5vw, 15px);
        padding: 4px 5px;
        text-decoration: none;
        display: block;

        &:hover {
            background-color: #f1f1f1
        }
    }
}


.page {
    max-width: 90%;
    margin: auto;
}

@media screen and (max-width: 800px) {
    .page {
        max-width: 100%;
    }
}

.gradient {
    /* color: rgb(2,0,36); */
    background: linear-gradient(90deg, $darkblue, $cyan 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    //    display: inline;
    //    font-weight: 900;
    //    font-size: inherit;
}

.container {
    margin: 2vw auto;
    background-color: #D6EFED;
    width: max-content;
    /* max-height: auto; */
    padding: max(2.8vw, 28px);
    border-radius: 10px;

    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
    }
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.header {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    justify-content: center;
    /* margin-bottom: 20px; */
}

.header2 {
    display: flex;
    font-size: 60px
    /* justify-content: center; */
    /* justify-content: center; */
    /* margin-bottom: 20px; */
}


.title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    /* margin-bottom: 20px; */
}

.btn {
    /* display: inline-block; */
    background: $darkblue;
    color: #fff;
    /* border: steelblue; */
    padding: max(1vw, 10px) max(2vw, 20px);
    margin: max(2vw, 20px);
    border-width: 0px;
    border-radius: max(1vw, 10px);
    cursor: pointer;
    /* float: right; */
    text-decoration: none;
    font-size: max(2vw, 20px);
    font-family: inherit;
    transition: color 0.3s;
    transition: background 0.3s;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    /* transition: scale 0.3s; */

    &:hover {
        background: $hover;
    }

    &:active {
        transform: scale(0.95);
    }
}

.practicediv {
    font-size: max(1.4vw, 14px);
    line-height: max(2.8vw, 28px);
}

.practicequiz {
    margin: max(3vw, 30px);
    background-color: #DDEEFF;
    border: max(0.3vw, 3px) solid #000000;
    font-size: max(1.4vw, 14px);
    line-height: max(2.8vw, 28px);
    overflow: auto;

    input {
        font-size: max(1.4vw, 14px);
        width: max(5vw, 50px);
        text-align: center;

        &.longFraction {
            width: max(8vw, 80px);
        }
    }

    .sign {
        width: max(2vw, 20px);
        height: max(2vw, 20px);
        margin-left: max(0.7vw, 7px);
        margin-right: max(0.7vw, 7px);
        border-width: max(0.07vw, 0.7px);
        border-radius: 50%;
        background-color: lightgray;
        color: black;

        &:hover {
            background-color: white;
        }
    }

    .btn {
        font-size: max(1.4vw, 14px);
        margin-bottom: 0;
    }

    .canvas {
        float: left;
        margin-left: 15%; //max(2vw, 20px);
        margin-right: 15%; //max(2vw, 20px);
        margin-top: max(2vw, 20px);
        margin-bottom: max(2vw, 20px);
    }

    .practiceheader {
        background-color: #D0E0F0;
        text-align: right;

        .practiceheaderleft {
            display: inline-block;
            white-space: nowrap;
            width: max(50%, 120px);
            text-align: center;
        }

        .practiceheaderright {
            display: inline-block;
            white-space: nowrap;
            width: max(50%, 150px);
            text-align: center;
        }
    }

    .practicebody {
        text-align: center;
        padding: max(2vw, 20px);

        .fractionAddition {
            display: flex;
            align-items: center;
            width: min-content;
            margin:auto;
        }

        .remainderTable {
            width: 80%;
            margin: auto;
            border-collapse: collapse;

            .invisible {
                display: none;
            }

            table {
                width: max(15vw,150px);
                margin-left: max(3vw,30px);
                margin-bottom: max(3vw,30px);
                table-layout: fixed;
                border: 2px solid black;
            }

            td {
                width: max(5vw,50px);
                height: max(5vw,50px);
                border: 2px solid black;
                font-size: max(2vw,20px);
            }

            input {
                width: max(3vw,30px);
                background: none;
                border: none;
                outline: none;
                text-align: center;
                color: blue;
                font-weight: bold;
                font-size: max(2vw,20px);

                &.correct {
                    color: #00AA00;
                }

                &.wrong {
                    color: #FF0000;
                }
            }
        }

        .magicSquares {
            width: 80%;
            margin: auto;

            .leftSquare {
                float: left;
            }

            .rightSquare {
                float: left;
            }

            .invisible {
                float: left;
                display: none;
            }

            table {
                width: max(15vw,150px);
                margin-left: max(3vw,30px);
                margin-bottom: max(3vw,30px);
                table-layout: fixed;
                border: 2px solid black;
                border-collapse: collapse;
            }

            td {
                width: max(5vw,50px);
                height: max(5vw,50px);
                border: 2px solid black;
                border-collapse: collapse;
                font-size: max(2vw,20px);
            }

            input {
                width: max(3vw,30px);
                background: none;
                border: none;
                outline: none;
                text-align: center;
                color: blue;
                font-weight: bold;
                font-size: max(2vw,20px);

                &.correct {
                    color: #00AA00;
                }

                &.wrong {
                    color: #FF0000;
                }
            }
        }
    }

    .choices {
        background: $darkblue;
        font-size: max(1.4vw, 14px);
        color: #fff;
        padding: max(0.5vw, 5px);
        margin: max(0.5vw, 5px);
        border-width: max(0.2vw, 2px);
        border-radius: max(0.7vw, 7px);
        border-style: solid;
        cursor: pointer;
        transition: color 0.3s;
        transition: background 0.3s;

        &:hover {
            background: $hover;
        }

        &:active {
            transform: scale(0.95);
        }

        &.correctchoice {
            background: #007700;
        }

        &.wrongchoice {
            background: #990000;
        }

        &.selected {
            background: darkblue;
        }

        &.multicorrect {
            border-color: #22FF22;
        }

        &.multiwrong {
            border-color: #990000;
        }
    }

    .fraction {
        margin: auto;

        .numerator {
            border-bottom: max(0.1vw, 1px) solid black;
        }
    }

    .inlineFraction {
        display: inline-block;
        vertical-align: middle;

        .numerator {
            border-bottom: max(0.2vw, 2px) solid black;
        }
    }
}

.practicequizbattle {
    margin: max(3vw, 30px);
    background-color: #DDEEFF;
    border: max(0.3vw, 3px) solid #000000;
    font-size: max(1.4vw, 14px);
    line-height: max(2.8vw, 28px);
    overflow: auto;

    input {
        font-size: max(1.4vw, 14px);
        width: max(5vw, 50px);
        text-align: center;

        &.longFraction {
            width: max(8vw, 80px);
        }
    }

    .sign {
        width: max(2vw, 20px);
        height: max(2vw, 20px);
        margin-left: max(0.7vw, 7px);
        margin-right: max(0.7vw, 7px);
        border-width: max(0.07vw, 0.7px);
        border-radius: 50%;
        background-color: lightgray;
        color: black;

        &:hover {
            background-color: white;
        }
    }

    .btn {
        font-size: max(1.4vw, 14px);
        margin-bottom: 0;
    }

    .canvas {
        float: left;
        margin-left: 15%; //max(2vw, 20px);
        margin-right: 15%; //max(2vw, 20px);
        margin-top: max(2vw, 20px);
        margin-bottom: max(2vw, 20px);
    }

    .onlineplayers {
        float: left;
        margin-left: max(1vw, 10px);
        margin-top: max(1vw, 10px);
        margin-bottom: max(1vw, 10px);
        padding-left: max(1vw, 10px);
        padding-right: max(1vw, 10px);
        border: 2px solid black;
    }

    .leaderboard {
        float: right;
        margin-right: max(1vw, 10px);
        margin-top: max(1vw, 10px);
        padding-left: max(1vw, 10px);
        padding-right: max(1vw, 10px);
        border: 2px solid black;
    }

    .practicebody {
        text-align: center;
        padding: max(2vw, 20px);

        .remainderTable {
            width: 80%;
            margin: auto;
            border-collapse: collapse;

            .invisible {
                display: none;
            }

            table {
                width: max(15vw,150px);
                margin-left: max(3vw,30px);
                margin-bottom: max(3vw,30px);
                table-layout: fixed;
                border: 2px solid black;
            }

            td {
                width: max(5vw,50px);
                height: max(5vw,50px);
                border: 2px solid black;
                font-size: max(2vw,20px);
            }

            input {
                width: max(3vw,30px);
                background: none;
                border: none;
                outline: none;
                text-align: center;
                color: blue;
                font-weight: bold;
                font-size: max(2vw,20px);

                &.correct {
                    color: #00AA00;
                }

                &.wrong {
                    color: #FF0000;
                }
            }
        }

        .magicSquares {
            width: 80%;
            margin: auto;

            .leftSquare {
                float: left;
            }

            .rightSquare {
                float: left;
            }

            .invisible {
                float: left;
                display: none;
            }

            table {
                width: max(15vw,150px);
                margin-left: max(3vw,30px);
                margin-bottom: max(3vw,30px);
                table-layout: fixed;
                border: 2px solid black;
                border-collapse: collapse;
            }

            td {
                width: max(5vw,50px);
                height: max(5vw,50px);
                border: 2px solid black;
                border-collapse: collapse;
                font-size: max(2vw,20px);
            }

            input {
                width: max(3vw,30px);
                background: none;
                border: none;
                outline: none;
                text-align: center;
                color: blue;
                font-weight: bold;
                font-size: max(2vw,20px);

                &.correct {
                    color: #00AA00;
                }

                &.wrong {
                    color: #FF0000;
                }
            }
        }
    }

    .choices {
        background: $darkblue;
        font-size: max(1.4vw, 14px);
        color: #fff;
        padding: max(0.5vw, 5px);
        margin: max(0.5vw, 5px);
        border-width: max(0.14vw, 1.4px);
        border-radius: max(0.7vw, 7px);
        cursor: pointer;
        transition: color 0.3s;
        transition: background 0.3s;

        &:hover {
            background: $hover;
        }

        &:active {
            transform: scale(0.95);
        }

        &.correctchoice {
            background: #007700;
        }

        &.wrongchoice {
            background: #990000;
        }
    }

    .fraction {
        margin: auto;

        .numerator {
            border-bottom: max(0.1vw, 1px) solid black;
        }
    }

    .inlineFraction {
        display: inline-block;
        vertical-align: middle;

        .numerator {
            border-bottom: max(0.2vw, 2px) solid black;
        }
    }
}

@media screen and (max-width: 800px) {
    .practicequiz {
        margin: 0;

        .canvas {
            float: none;
            margin-bottom: 0;
        }
    }
    .practicequizbattle {
        margin: 0;

        .canvas {
            float: none;
            margin-bottom: 0;
        }
    }}

.collapsible {
    margin-top: max(0.5vw, 5px);
    color: black;
    cursor: pointer;
    padding: max(1vw, 10px) max(5vw, 50px) max(1vw, 10px) max(5vw, 50px);
    border: none;
    background-image: linear-gradient(90deg, #A4DBE8, #A4DBE8);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left bottom;
    width: 100%;
    text-align: left;
    align-items: center !important;
    font-size: max(1.6vw, 16px);
    border-radius: max(1.5vw, 15px);

    &::after {
        content: "+";
        color: black;
        font-weight: bold;
        float: right;
        margin-left: 5px;
    }

    &.cactive::after {
        content: "-";
    }
}

.content {
    padding: 3px 8vw;
    //    background-color: #F4F4F4;
    font-size: max(1.4vw, 14px);
    line-height: max(2.8vw, 28px);
    max-height: 0;
    white-space: pre-wrap;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
}

.contestContent {
    padding: 3px;
    //    background-color: #F4F4F4;
    font-size: max(1.4vw, 14px);
    line-height: max(2.8vw, 28px);
    max-height: 0;
    white-space: pre-wrap;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
}

.practicecollapsible {
    max-height: 100%;
}

.centered {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.swiper-slide {
    text-align: center;
    background: #fff;
}

.backtotop {
    font-weight: bold;
    background-image: none;
    padding: max(0.5vw, 5px);
    margin-top: max(1vw, 10px);
    margin-bottom: max(2vw, 20px);
    border-radius: max(0.5vw, 5px);
    border: 1px solid black;
    background-color: #AAAAAA;
    font-size: max(1vw, 10px);
}

#navsticky {
    background-color: #FFFFFF;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 10;
    border-bottom: max(0.3vw, 3px) solid #EEEEEE;
    display: flex;
    justify-content: space-between;
    padding: max(1vw, 10px) max(1.5vw, 15px);
    line-height: max(2.5vw, 25px);

    ul {
        margin: 0;
        display: flex;
        gap: max(1.5vw, 15px);
        position: absolute;
        right: max(1vw, 10px);
        bottom: max(1vw, 10px);
    }

    .icon {
        display: none;
        font-weight: bold;
        background-image: none;
        border: none;
        background-color: #FFFFFF;
    }

    .navlink {
        background-image: linear-gradient(90deg, $cyan 0%, $darkblue 100%);
        background-size: 0% max(0.3vw, 3px);
        background-repeat: no-repeat;
        background-position: left bottom;
        color: #000000;
        font-size: max(1.5vw, 15px);
        text-decoration: none;
        transition: background-size 0.5s ease;

        &:hover {
            background-size: 100% max(0.3vw, 3px);
        }

        &.active {
            background-size: 100% max(0.2vw, 2px);
        }
    }
}

@media screen and (max-width: 800px) {

    #navsticky {

        ul {
            display: none;

            &.expanded {
                float: right;
                display: block;
                text-align: left;
                margin-right: 10vw;
                position: static;
            }
        }

        .icon {
            display: inherit;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 20px;
        }
    }
}

@media screen and (max-width: 600px) {
    #footerdiv {
        display: block !important;

        div {
            text-align: center;
            //            margin-top: max(2vw, 20px);
        }
    }
}

footer {
    text-align: center;
    width: 100%;
    font-size: max(1.4vw, 14px);
    text-decoration: none !important;
    color: #000;
    background-color: #fff;
    border-top: max(0.3vw, 3px) solid #EEEEEE;
    padding-top: max(3vw, 30px);
    margin-top: max(30vw, 300px);
    display: block;
    line-height: max(2.8vw, 28px);
    margin-bottom: -1000%;

    #footerdiv {
        display: flex;
        justify-content: space-between;
        padding: 0 max(8vw, 80px) 0 max(8vw, 80px);
    }
}
